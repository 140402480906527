<!--
  -- Created by madny on 2022/10/14
  -- 【发货页面】
-->
<template>
  <moe-page title="商品详情" class="shipment">

    <div class="df aic">
      <moe-describe-list title="买家收货信息" :col="1" v-if="orderDetail.oldAddress">
        <div class="ml-30 color-danger">旧收货地址</div>
        <moe-describe-item label="收货姓名">{{ orderDetail.oldContact }}</moe-describe-item>
        <moe-describe-item label="联系电话">{{ orderDetail.oldMobile }}</moe-describe-item>
        <moe-describe-item label="收货地址">{{ orderDetail.oldAddress }}</moe-describe-item>
      </moe-describe-list>
      <moe-describe-list title="买家收货信息" :col="1">
        <div class="ml-30 color-danger" v-if="orderDetail.oldAddress">新收货地址</div>
        <moe-describe-item label="收货姓名">{{ orderDetail.contact }}</moe-describe-item>
        <moe-describe-item label="联系电话">{{ orderDetail.mobile }}</moe-describe-item>
        <moe-describe-item label="收货地址">
          <div class="df aic">
            <div>{{ orderDetail.address }}</div>
            <!-- 待发货支持修改收货地址 -->
            <div class="ml-20" v-if="['PENDING_SHIPMENT'].includes(orderDetail.payStatus)">
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="handleAddress()">修改收货地址</el-button>
            </div>
          </div>
        </moe-describe-item>
      </moe-describe-list>
    </div>
    <!-- 挂阁 -->
    <moe-describe-list title="确认订单信息">
      <moe-describe-item label="订单编号">{{ orderDetail.orderNo }} </moe-describe-item>
      <moe-describe-item label="支付时间" v-if="orderDetail.payTime">{{ orderDetail.payTime }} </moe-describe-item>
    </moe-describe-list>

    <moe-describe-list title="订单备注">
      <moe-describe-item>{{ orderDetail.userRemark || '无备注' }}</moe-describe-item>
    </moe-describe-list>

    <div style="width: 100%;">
      <moe-table
        ref="orderTable"
        style="width: 100%;"
        :data="orderDetail.orderItemList"
        :mode="false"
        :params="{ pageNum: 1 }"
        :number-show="false"
        rowKey="id"
        @selectChange="selectChange">

        <el-table-column :selectable="selectable" type="selection" width="55" />

        <el-table-column prop="goodsName" label="商品信息" min-width="200">
          <template slot-scope="{ row }">
            <div class="df aic jcc">
              <moe-image :src="row.coverUrl" width="80px" height="80px" />
              <div style="text-align:left" class="df1 ml-10">
                <p class="fwb">{{ row.goodsName }}</p>
                <div class="df aic">
                  <p class="font-12 color-info">{{ row.goodsItemValue }}</p>
                  <p v-if="row.fromGift" class="b-primary color-primary w-35 h-25 lh-25 tac ml-10">赠品</p>
                </div>
                <p class="color-danger fwb"> ￥{{ row.money }} <span class="ml-10 color-info"> x {{ row.number }}</span></p>
                <p class="font-12 color-info">仓库编码 {{ row.identifier }}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" label="状态" min-width="130">
          <template slot-scope="{ row }">
            <div>{{ $moe_format.getPayStatus(row.payStatus) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="优惠金额" min-width="130">
          <div class="df fdc aic" slot-scope="{ row }">
            <div v-html="discountHtml(row.discountList)"></div>
          </div>
        </el-table-column>
        <el-table-column prop="totalPrice" label="总价" min-width="150" />
      </moe-table>

      <div class="df fdc aife">
        <div class="mr-30 mb-10">运费：<span class="fwb" :class="{'color-danger': $moe_math.mathGreaterThan(orderDetail.freight) }">
          {{ $moe_math.mathGreaterThan(orderDetail.freight) ? `￥ ${orderDetail.freight}` : '包邮'  }}</span>
        </div>
        <div class="mr-30">实收款：<span class="color-danger fwb">￥{{ orderDetail.payPrice }}</span></div>
      </div>
    </div>

    <moe-form ref="shipmentForm" class="default-form" :model="form" :showBack="false">
      <div class="shipment_item" v-for="(item, index) in form.shipment" :key="index">
        <div class="df">
          <el-form-item :label="`包裹${index + 1}`" :prop="`shipment.${index}.expressNo`" :rules="rules.expressNo">
            <el-input style="width: 300px;" v-model.trim="item.expressNo" :value="item.expressNo" placeholder="请输入物流单号" maxlength="20" clearable @input="(e) => handleInput(e, index)"></el-input>
          </el-form-item>
          <el-form-item label-width="10px" :prop="`shipment.${index}.com`" :rules="rules.com">
            <el-select style="width: 300px" v-model="item.com" placeholder="请选择物流公司" filterable clearable value-key="com" @change="(e) => changeExpress(e, index)">
              <el-option :label="item.name" :value="item.com" v-for="item of expressList" :key="item.com" />
            </el-select>
          </el-form-item>
          <el-form-item label-width="10px" :prop="`shipment.${index}.remark`">
            <el-input style="width: 200px;" v-model.trim="item.remark" placeholder="备注，如赠品或拆包" maxlength="20" clearable></el-input>
          </el-form-item>
          <el-form-item label-width="0" v-if="form.shipment.length > 1">
            <div class="pl-10 pr-10 df aic cp" style="height: 36px;" @click="handleDelete(index)">
              <i class="el-icon-delete font-20"></i>
            </div>
          </el-form-item>
        </div>
        <div class="ml-10" v-if="form.shipment.length > 1">
          <el-form-item label-width="0" :prop="`shipment.${index}.orderNoItem`" :rules="rules.orderNoItem">
            <div class="font-14 color-info">关联商品</div>
          </el-form-item>

          <div class="mb-10 df" v-for="(orderNoItem, orderNoIndex) in item.orderNoItem" :key="orderNoIndex">
            <el-form-item label-width="0" :prop="`shipment.${index}.orderNoItem.${orderNoIndex}.belowOrderNo`" :rules="rules.belowOrderNo">
              <el-select
                v-model="orderNoItem.belowOrderNo"
                style="width: 500px"
                clearable
                @change="(value) => changeGoodsList(value, index, orderNoIndex)">
                <el-option v-if="orderNoItem.belowOrderNo" :label="`${orderNoItem.selectedItem.goodsName}/${orderNoItem.selectedItem.goodsItemValue}`" :value="orderNoItem.selectedItem.belowOrderNo"></el-option>
                <el-option v-for="(goodsItem, goodsIndex) in getGoodsList(index)" :key="goodsIndex" :value="goodsItem.belowOrderNo" :label="`${goodsItem.goodsName}/${goodsItem.goodsItemValue}`">{{ `${goodsItem.goodsName}/${goodsItem.goodsItemValue}` }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width="10px">
              <div class="df">
                <el-input-number class="mr-10" style="width: 150px;" v-model="orderNoItem.number" :min="1" step-strictly @blur="(e) => blurInputNumber(e, index, orderNoIndex)"></el-input-number>
                <div class="mr-10">件</div>
                <div class="mr-10 df aic cp" style="height: 36px;" @click="handleAddOrderNoItem(index)">
                  <i class="el-icon-circle-plus-outline font-20"></i>
                </div>
                <div class="df aic cp" style="height: 36px;" @click="handleDeleteOrderNoItem(index, orderNoIndex)" v-if="item.orderNoItem.length > 1">
                  <i class="el-icon-remove-outline font-20"></i>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </div>

      <div class="ml-10">
        <el-button style="width: 150px;" size="medium" type="primary" plain @click="handleAdd()">添加新包裹</el-button>
      </div>

      <template slot="tool">
        <el-button :loading="shipmentLoad" type="primary" @click="submit()">{{ shipmentLoad ? '请稍候' : '确认并发货' }}</el-button>
      </template>
    </moe-form>

    <moe-dialog :show="showDialog" title="修改收货地址" width="50%" :autoHeight="true" @close="dialogClose()">
      <moe-form
        ref="addressForm"
        :showBack="false"
        :showTool="false"
        :model="addressParams"
        :rules="rules">
        <el-form-item label="收货人" prop="contact">
          <el-input type="text" placeholder="请输入收货人" v-model="addressParams.contact" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input type="text" placeholder="请输入手机号码" maxlength="30" :value="addressParams.mobile" @input="(value) => addressParams.mobile = $moe_formatter.formatterNoChinese(value)"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" prop="selectList">
          <moe-address className="w-400" v-model="addressParams.selectList" :getSelectTextList="true" :selectTextList.sync="addressParams.selectTextList"></moe-address>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            type="textarea"
            placeholder="请输入详细地址"
            v-model="addressParams.address"
            :autosize="{ minRows: 3, maxRows: 4 }"
            maxlength="100"
            show-word-limit>
          </el-input>
        </el-form-item>
      </moe-form>

      <template slot="footer">
        <el-button @click="dialogClose()">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="req_updateAddress()">确 定</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'OrderDetail',
  data() {
    //订单信息
    const orderNoItem = {
      belowOrderNo: '',
      number: 1,
      superiorOrderNo: '',
      selectedItem: {},
    }
    const shipmentItem = {
      orderNoItem: [this.$moe_lodash.cloneDeepData(orderNoItem)],
      goodsList: [],
      mobile: '',
      com: '',
      name: '',
      expressNo: '',
      remark: '',
    }
    // 菜单数据校验
    const verify = this.$moe_verify.verifyForm;
    return {
      orderDetail: {},
      rules: {
        com: verify.isEmpty('请选择物流公司名称'),
        expressNo: verify.isEmpty('请输入物流单号'),
        orderNoItem: verify.isEmpty('请关联商品'),
        belowOrderNo: verify.isEmpty('请关联商品'),
        contact: verify.isEmpty('请输入收货人姓名'),
        mobile: verify.isEmpty('请输入手机号码'),
        selectList: verify.isEmpty('请选择收货地址'),
        address: verify.isEmpty('请输入详细地址'),
      },
      form: {
        shipment: [this.$moe_lodash.cloneDeepData(shipmentItem)],
      },
      shipmentItem,
      orderNoItem,
      shipmentLoad: false,
      expressList: [],
      selectList: [],
      timer: null,
      showDialog: false,
      loading: false,
      addressParams: {
        selectList: [],
        selectTextList: [],
        contact: '',
        mobile: '',
        address: '',
      },
    }
  },
  created() {
    //获取商品信息
    this.getOrderDetail();
    this.getAllMenuList();
  },
  methods: {
    discountHtml(discountList) {
      if (discountList && discountList.length) {
        let html = '', discountAll = 0;
        discountList.forEach(({ name, discount }) => {
          discountAll = this.$moe_math.mathAdd(discountAll, discount);

          html += `<div>${name}: -${discount}</div>`;
        })

        return `<div>¥ ${discountAll}</div>${html}`;
      } else {
        return '-'
      }
    },
    selectable({ payStatus, canDeliver }) {
      // if (['PENDING_SHIPMENT'].includes(payStatus)) {
      //   return true
      // }
      // return false
      return canDeliver
    },
    handleAddress() {
      this.showDialog = true;
      this.$nextTick(() => {
        this.$refs['addressForm'].clearValidate();
      })
    },
    /** 修改收货地址 */
    req_updateAddress() {
      this.$refs['addressForm'].validate(() => {
        this.$moe_layer.confirm(`请确认地址信息是否无误？`, () => {
          this.loading = true;
          const { contact, mobile, selectTextList, address } = this.addressParams;
          let params = {
            orderNo: this.orderDetail.orderNo,
            contact,
            mobile,
            address: `${selectTextList.join(' ')} ${address}`,
          }
          this.$moe_api.ORDER_API.updateAddress(params).then((data) => {
            if (data.code === 200) {
              this.$moe_msg.success('修改收货地址成功', {
                completionHandler: () => {
                  this.dialogClose();
                  this.getOrderDetail();
                }
              });
            } else {
              this.$moe_msg.error(data.message);
            }
          }).finally(() => {
            this.loading = false;
          });
        })
      })
    },
    /** 弹框关闭 */
    dialogClose() {
      this.showDialog = false;
      this.addressParams = {
        selectList: [],
        selectTextList: [],
        contact: '',
        mobile: '',
        address: '',
      }
      this.$refs['addressForm'].clearValidate();
    },
    /** 添加新包裹 */
    handleAdd() {
      this.form.shipment.push({
        ...this.shipmentItem,
        orderNoItem: [this.$moe_lodash.cloneDeepData(this.orderNoItem)],
        goodsList: this.selectList
      });
    },
    handleAddOrderNoItem(index) {
      if (this.form.shipment[index].orderNoItem.length >= this.selectList.length) {
        return this.$moe_msg.warning(`最多添加${this.selectList.length}个商品`);
      }

      this.form.shipment[index].orderNoItem.push(this.$moe_lodash.cloneDeepData(this.orderNoItem));
    },
    /** 删除包裹 */
    handleDelete(index) {
      this.form.shipment.splice(index, 1);
      if (this.form.shipment.length === 1) {
        this.form.shipment[0].orderNoItem.splice(0);
        this.form.shipment[0].orderNoItem.push(this.$moe_lodash.cloneDeepData(this.orderNoItem));
      }
    },
    /** 删除关联商品 */
    handleDeleteOrderNoItem(index, orderNoIndex) {
      this.form.shipment[index].orderNoItem.splice(orderNoIndex, 1);
    },
    selectChange(list) {
      this.selectList = list;

      this.form.shipment.forEach((item) => {
        item.goodsList = this.selectList;
      })

      const selectedValue = this.orderDetail.orderItemList.filter(({ belowOrderNo }) => !this.selectList.map(({ belowOrderNo }) => belowOrderNo).includes(belowOrderNo)).map(({ belowOrderNo }) => belowOrderNo);

      this.form.shipment.forEach((item, index) => {
        item.goodsList.forEach((goodsListItem, goodsListIndex) => {
          if (selectedValue.includes(goodsListItem.belowOrderNo)) {
            item.goodsList.splice(goodsListIndex, 1);
          }
        })

        item.orderNoItem.forEach((orderNoItemItem, orderNoItemIndex) => {
          if (selectedValue.includes(orderNoItemItem.belowOrderNo)) {
            if (item.orderNoItem.length === 1) {
              item.orderNoItem[0].belowOrderNo = '';
            } else {
              item.orderNoItem.splice(orderNoItemIndex, 1);
            }
          }
        })
      })
    },
    getGoodsList(index) {
      const selectedValue = this.form.shipment[index].orderNoItem.map(({ belowOrderNo }) => belowOrderNo);
      return this.form.shipment[index].goodsList.filter(({ belowOrderNo }) => !selectedValue.includes(belowOrderNo) );
    },
    changeGoodsList(value, index, orderNoIndex) {
      this.form.shipment[index].orderNoItem[orderNoIndex].selectedItem = this.form.shipment[index].goodsList.find((item) => item.belowOrderNo === value);
    },
    handleInput(value, index) {
      try {
        this.form.shipment[index].expressNo = this.$moe_formatter.formatterNoChinese(value).replace(/ /g, '');
        // 清除之前的定时器
        clearTimeout(this.timer)
        if (this.form.shipment[index].expressNo) {
          this.timer = setTimeout(() => {
            this.$moe_api.ORDER_API.orderAutoNumber({ expressNo: this.form.shipment[index].expressNo }).then((data) => {
              if (data.code === 200) {
                let list = data.result.list
                if (list.length) {
                  list.some(({ comCode }) => {
                    let findItem = this.expressList.find(({ com }) => com === comCode);
                    if (findItem) {
                      this.form.shipment[index].name = findItem.name;
                      this.form.shipment[index].com = comCode;
                      return true; // 跳出外层循环
                    }
                    return false;
                  });
                } else {
                  this.form.shipment[index].name = '';
                  this.form.shipment[index].com = '';
                }
              } else {
                this.$moe_message.error(data.message);
              }
            })
          }, 300)
        }
      } catch {
        console.log('error');
      }
    },
    changeExpress(e, index) {
      this.form.shipment[index].name = this.expressList.find(({ com }) => e === com).name;
    },
    /** 数量输入框失去焦点事件 */
    blurInputNumber(e, index, orderNoIndex) {
      if (this.form.shipment[index].orderNoItem[orderNoIndex].number === undefined) {
        this.form.shipment[index].orderNoItem[orderNoIndex].number = 1;
      }
    },
    /**
     * 获取商品详情
     **/
    getOrderDetail() {
      this.$moe_api.ORDER_API.queryOrderListById({ orderNo: this.$route.query.orderNo }).then((data) => {
        if (data.code == 200) {
          this.orderDetail = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    /**
      * 获取所有物流公司信息
      **/
    getAllMenuList() {
      this.$moe_api.GENERAL_API.allExpressList().then((data) => {
        if (data.code == 200) {
          this.expressList = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      });
    },
    deliverGoods() {
      let dto = this.$moe_lodash.cloneDeepData(this.form.shipment);

      if (dto.length === 1) {
        dto = dto.map(({ com, expressNo, name, remark }) => {
          return {
            com,
            expressNo,
            name,
            remark,
            orderNoItem: this.selectList.map(({ belowOrderNo, number }) => {
              return {
                belowOrderNo,
                number,
                superiorOrderNo: this.orderDetail.orderNo
              }
            }),
            mobile: this.orderDetail.mobile,
          }
        })
      } else if (dto.length > 1) {
        dto = dto.map(({ com, expressNo, name, remark, orderNoItem }) => {
          return {
            com,
            expressNo,
            name,
            remark,
            orderNoItem: orderNoItem.map(({ belowOrderNo, number }) => {
              return {
                belowOrderNo,
                number,
                superiorOrderNo: this.orderDetail.orderNo
              }
            }),
            mobile: this.orderDetail.mobile,
          }
        })
      }

      this.$moe_api.ORDER_API.deliverGoods({ dto }).then((data) => {
        if (data.code == 200) {
          this.$moe_msg.success('发货成功');
          this.$refs['orderTable'].clearSelect();
          this.getOrderDetail();
          this.$moe_coordinator.redirectTo(`/order/detail?orderNo=${this.$route.query.orderNo}`);
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.shipmentLoad = false;
      });
    },
    /**
     * 提交发货请求
     **/
    submit() {
      if (!this.selectList.length) {
        return this.$moe_msg.warning('请选择商品');
      }
      this.$refs['shipmentForm'].validate(() => {
        this.shipmentLoad = true;
        let findPendingShipmentList = this.orderDetail.orderItemList.filter(({ payStatus }) => ['PENDING_SHIPMENT'].includes(payStatus)) //未发货订单list

        if (this.selectList.length < findPendingShipmentList.length) { //选中的未发货订单list < 未发货订单list
          this.$moe_layer.confirm('您有部分商品未勾选，该订单将被拆单发货；未勾选商品本次不发货', () => {
            this.deliverGoods();
          }, () => {
            this.shipmentLoad = false;
          })
        } else {
          this.deliverGoods();
        }
      });
    },
  },
}
</script>
<style lang="scss">
.shipment{
  &_item {
    border: 1px solid #EBEEF5;
    padding: 30px;
    border-radius: 4px;
    margin: 10px;
  }
  .el-table--fit{
    height: auto!important;
    border:1px solid #EBEEF5;
    border-bottom: 0;
  }
  .el-table__header,
  .el-table__body{
    border:0;
  }
  .el-table__body-wrapper{
    height: auto !important;
  }
  .l-describe-list{
    overflow: visible;
  }
  .el-step__head.is-success{
    color:#333;
    border-color: #333;
  }
  .el-step__title.is-success{
    color: #333;
  }
  .el-step__title.is-process{
    color: $--color-primary;
  }
  .el-step__head.is-process{
    color: $--color-primary;
    border-color: $--color-primary;
  }

}
</style>